import React from "react"
import {graphql} from "gatsby"
import Seo from "../components/_shared/Seo"
import SliceZone from "../components/SliceZone"

const GalleryPage = ({data}) => {

    if (!data) return null

    const pageContent = data.prismicGallerypage || {}

    return (

        <>
            <SliceZone
                body={pageContent.data.body}
                page={pageContent.uid}
            />
            
        </>
    )
}

export const Head = ({ data }) => (
    <Seo title={data.prismicGallerypage.data.seo_title} />
);


export const query = graphql`
  query GallerypageQuery($lang: String) {
     prismicGallerypage(lang: { eq: $lang }) {
        
        id
        uid
        data {
          title {
            richText
          }
          seo_title
          body {
            ... on PrismicGallerypageDataBodyPhotogallery {
              id
              slice_type
              slice_label
              primary {
                gallery_name {
                  richText
                }
              }
              items {
                photo_name
                photo {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
        
      }
  }
`

export default GalleryPage;



